:root:root {
  --adm-color-primary: #00c250;
}

.body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.box-sizing-border {
  box-sizing: border-box;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  width: 100% !important;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-baseline {
  align-items: baseline;
}

.flex-column {
  flex-direction: column;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-26 {
  font-size: 26px;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-bolder {
  font-weight: bolder;
}

.ml20 {
  margin-left: 20px;
}

.ml10 {
  margin-left: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.px20 {
  padding-left: 20px;
  padding-right: 20px;
}
.py30 {
  padding-top: 30px;
  padding-bottom: 30px;
}


.adm-radio-icon {
  position: absolute;
  right: 20px;
}